import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Container from "../components/Container";
import { Flex, Box } from "@rebass/grid";
import { OutboundLink } from "gatsby-plugin-google-analytics";

import H1 from "../components/H1";
import H2 from "../components/H2";
import H3 from "../components/H3";
import H4 from "../components/H4";
import Lead from "../components/Lead";
import Button from "../components/Button";
import Paragraph from "../components/Paragraph";

export const VacanciesPageTemplate = () => {
  return (
    <Container>
      <Helmet title={`Vacatures | Appmantle`}>
        <meta name="description" content="Werken bij Appmantle" />
        <link rel="canonical" href="https://appmantle.com/vacatures/" />
      </Helmet>
      <Flex>
        <Box mt={[4, 5, 6]} mb={[4, 5, 5]}>
          <H1 as="h1">Vacatures</H1>
        </Box>
      </Flex>
      <Flex flexWrap="wrap" mb={6}>
        <H3>Er zijn op dit moment geen openstaande vacatures.</H3>
      </Flex>
    </Container>
  );
};

const VacanciesPage = ({ data }) => {
  return (
    <Layout>
      <VacanciesPageTemplate />
    </Layout>
  );
};

export default VacanciesPage;
